import Axios from 'axios';
import moment from 'moment';

export default {

  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      hirings: [],
      hirings_squads: [],
      notifications: [],
      banner: {
        source: '',
        author: '',
      },
      topOne: 0,
      syncing: true,
      reviewStatus: 1,

      page: 0,
      perPage: 9,
      resultCount: 0,
    }
  },

  mounted() {

    $('[data-toggle="tooltip"]').tooltip();

    // if ($('.hero-admin-clean-client').length) {
    //   $('.conditional-row').addClass('min-h-lg-90vh');
    // }

    feather.replace();

    if (this.$store.state.role == 'admin') this.$router.push({ path: '/dashboard/admin' });
    if (this.$store.state.role == 'professional') this.$router.push({ path: '/dashboard/professional' });

    axios
      .get('/api/v1/banners', {})
      .then(response => {
        if (!response.data.error) {
          var banners = response.data.data;
          this.banner = banners[Math.floor(Math.random() * banners.length)];
        } else {
          console.log(response.data.error);
        }
      });

    axios
      .get('/api/v1/notifications?unread=1', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        let notifications = (response.data && response.data.data) ? response.data.data : [];
        this.notifications = notifications.slice(0, 3);
        this.$store.commit('setNotifications', notifications);
      });

    axios
      .get('/api/v1/hirings?all&company=' + this.me.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        this.hirings = (response.data) ? response.data.data : [];
        // console.log(this.hirings);
        this.syncing = false;
        if (this.topOneToEvaluate.id) {
          $('#evaluate-modal').modal('show');
        }
      });

    axios
      .get('/api/v1/companies/' + this.me.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        this.company = (response.data) ? response.data.data : {};
        this.$store.commit('setCompany', this.company);
      });

      axios
      .get('/api/v1/squads?company_id=' + this.me.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        this.hirings_squads = response.data
      });

  },

  computed: {

    open() {
      // status 1, 2, 3
      // aberto, selecão, mas não cancelado ou encerrado
      return this.hirings.filter(hiring => {
        return (hiring.status < 4);
      });
    },

    active() {
      // status 4
      // ativo mas não cancelado ou encerrado
      return this.hirings.filter(hiring => {
        let end = moment(hiring.date_end);
        let yesterday = moment().subtract(1, 'd');
        return (hiring.status == 4 && end > yesterday && !hiring.squad_id);
      });
    },

    past() {
      let _this = this;
      // status 1, 2, 3, ou 4
      // aberto, selecão, ativo mas não cancelado ou encerrado
      return this.hirings.filter(hiring => {
        if (!hiring.provider_id) return false;
        let i = 0;
        let review = true;
        let end = moment(hiring.date_end);
        let yesterday = moment().subtract(1, 'd');
        hiring.reviews.forEach((r) => {
          if (r.user_id == _this.me.id) review = false;
        });
        return (hiring.status == 4 && end <= yesterday && review);
      });
    },

    topOneToEvaluate() {
      if (this.past.length > this.topOne) {
        return this.past[this.topOne];
      } else {
        return { id: null };
      }
    },

    totalPages() {
      console.log(Math.ceil(this.resultCount / this.perPage) + " totalPages");
      return Math.ceil(this.resultCount / this.perPage);
    }

  },

  methods: {
    newBrief() {

      if (!this.company.razao || this.company.razao == '' ||
        !this.company.cnpj || this.company.cnpj == '' ||
        !this.company.phone || this.company.phone == '' ||
        !this.company.address || this.company.address.length < 1 ||
        (!this.company.address[0].city_id && !this.company.address[0].city_open) ||
        !this.me.name || this.me.name == '') {
        $('#complete-info-modal').modal('show');
      } else {
        this.$router.push('/dashboard/client/new-briefing');
      }
    },

    evaluate(data) {
      var _this = this;
      _this.reviewStatus = 2;
      axios
        .post('/api/v1/reviews?hiring=' + data.hiring, data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            // Avaliação enviada com sucesso!
            _this.reviewStatus = 3;
            setTimeout(() => {
              _this.topOne++;
              // check if selected is bigger than list
              if (_this.past.length == _this.topOne) {
                $('#evaluate-modal').modal('hide');
              }
              _this.reviewStatus = 4;
              setTimeout(() => {
                _this.reviewStatus = 1;
              }, 300);
            }, 2000);
          } else {
            console.log(response.data.error);
          }
        });
    },

    markRead(notification) {
      axios
        .put('/api/v1/notifications/' + notification.id + '/mark-read', {}, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.$router.push({ path: notification.url })
          } else {
            console.log(response.data.error);
          }
        });
    },

    nextPage() {
      this.page++;
    },

    prevPage() {
      this.page--;
    },

    paginate(list) {
      this.resultCount = list.length;
      if (this.page >= this.totalPages) {
        this.page = Math.max(0, this.totalPages - 1);
      }
      var index = this.page * this.perPage;
      return list.slice(index, index + this.perPage);
    },

  },

  filters: {

    paginate(list) {
      this.resultCount = list.length;
      if (this.page >= this.totalPages) {
        this.page = Math.max(0, this.totalPages - 1);
      }
      var index = this.page * this.perPage;
      return list.slice(index, index + this.perPage);
    },

  }

}
